import * as types from './actionTypes';
export const listAcenteLerRequest = () => ({
  type: types.LIST_ACENTELER_REQUEST,
});

export const addAcenteRequest = (acenteData) => ({
  type: types.ADD_ACENTE_REQUEST,
  payload: acenteData,
});

export const updateAcenteRequest = (acenteData) => ({
  type: types.UPDATE_ACENTE_REQUEST,
  payload: { acenteData },
});

export const deleteAcenteRequest = (id) => ({
  type: types.DELETE_ACENTE_REQUEST,
  payload: id,
});
