import * as types from './actionTypes';
export const listPolicelerRequest = () => ({
  type: types.LIST_POLICELER_REQUEST,
});

export const addPoliceRequest = (addData) => ({
  type: types.ADD_POLICE_REQUEST,
  payload: addData,
});

export const updatePoliceRequest = (updateData) => ({
  type: types.UPDATE_POLICE_REQUEST,
  payload: { updateData },
});

export const deletePoliceRequest = (id) => ({
  type: types.DELETE_POLICE_REQUEST,
  payload: id,
});