import PropTypes from "prop-types"
import React, { useState } from "react"
import { Button, Card, CardBody, Col, Container, Input, Row } from "reactstrap"
import { Slide, ToastContainer } from "react-toastify"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Spinners from "../../components/Common/Spinner"
import { withTranslation } from "react-i18next"

const PoliceSatisVerileri = (props) => {
  // Meta title
  document.title = "Poliçe Satış Verileri | ZUZZUU"
  const [isLoading, setLoading] = useState(false)
  const [isUploading, setUploading] = useState(false)

  const handleFileUpload = (event) => {
    setUploading(true)
    const file = event.target.files[0]
    if (file) {
      if (
        file.type === "application/vnd.ms-excel" ||
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        console.log("Selected file:", file)
      }
    }
    setTimeout(() => {
      setUploading(false)
    }, 3000)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <ToastContainer transition={Slide} />
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={"Veri İşlemleri"}
            breadcrumbItem={props.t("Poliçe Satış Verileri")}
          />
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row className="d-flex justify-content-end">
                      <Col className="d-flex justify-content-end" sm={2}>
                        <Input
                          type="file"
                          name="file"
                          className="d-none"
                          accept=".xls, .xlsx"
                          onChange={handleFileUpload}
                        />
                        <Button
                          type="button"
                          className="btn bg-primary"
                          disabled={isUploading}
                          onClick={() =>
                            !isUploading &&
                            document.getElementsByName("file")[0].click()
                          }
                        >
                          Dosya Yükle
                          {isUploading ? (
                            <i className="bx bx-dots-horizontal-rounded bx-spin-hover align-baseline ms-1" />
                          ) : (
                            <i className="bx bx-upload align-baseline ms-1" />
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

PoliceSatisVerileri.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(PoliceSatisVerileri)
