import * as types from "./actionTypes"

const initialState = {
  policePrim: {},
  segmentDetail: {},
  loading: {},
  error: {}
}

const policePrimHedef = (state = initialState, action) => {
  switch (action.type) {
    case types.LIST_POLICE_PRIM_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload.partajNo]: action.payload.status
        },
        error: {
          ...state.error,
          [action.payload.partajNo]: null
        }
      }
    case types.LIST_POLICE_PRIM_SUCCESS:
      console.log("reducer",action.payload.police_list)
      return {
        ...state,
        policePrim: {
          ...state.policePrim,
          [action.payload.partajNo]: action.payload.police_list
        },
        segmentDetail: {
          ...state.segmentDetail,
          [action.payload.partajNo]: action.payload.segments
        },
        loading: {
          ...state.loading,
          [action.payload.partajNo]: false
        },
        error: {
          ...state.error,
          [action.payload.partajNo]: null
        }
      }
    case types.LIST_POLICE_PRIM_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload.partajNo]: false
        },
        policePrim: {
          ...state.policePrim,
          [action.payload.partajNo]: []
        },
        error: {
          ...state.error,
          [action.payload.partajNo]: action.payload.errorMessage
        }
      }
    default:
      return state
  }
}

export default policePrimHedef
