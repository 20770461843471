import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"

import {
  listBolgelerRequest as onListBolgeler,
  addBolgeRequest as onAddBolge,
  updateBolgeRequest as onUpdateBolge,
  deleteBolgeRequest as onDeleteBolge
} from "../../store/bolgeler/actions"
import * as Yup from "yup"
import { useFormik } from "formik"
import { createSelector } from "reselect"
import DeleteModal from "../../components/Common/DeleteModal"
import { Slide, toast, ToastContainer } from "react-toastify"
import Spinners from "../../components/Common/Spinner"
import TableContainer from "../../components/Common/TableContainer"


const Bolgeler = (props) => {
  //meta title
  document.title = "Bölgeler | ZUZZUU"
  const dispatch = useDispatch()

  const [bolge, setBolge] = useState()
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (bolge && bolge.id) || "",
      bolge_adi: (bolge && bolge.bolge_adi) || "",
      bolge_description: (bolge && bolge.bolge_description) || "",
      bolge_kod: (bolge && bolge.bolge_kod) || ""
    },
    validationSchema: Yup.object({
      bolge_adi: Yup.string().required("Lütfen Bolge Adını Girin"),
      bolge_kod: Yup.number().nullable(""),
      bolge_description: Yup.string().required("Lütfen Açıklamayı Girin")
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateBolge = {
          id: bolge.id,
          ...values
        }

        // update bolge
        dispatch(onUpdateBolge(updateBolge))
      } else {
        const { id, ...newBolge } = values
        dispatch(onAddBolge(newBolge))
      }
      toggle()
    }
  })

  const { bolgeler, loading, error } = useSelector(state => state.Bolgeler);

  const [isLoading, setLoading] = useState(loading)

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  useEffect(() => {
    dispatch(onListBolgeler())
    setIsEdit(false)
  }, [dispatch])

  useEffect(() => {
    if (error) {
      toast.error(error)
    }
  }, [error])

  const toggle = () => {
    setModal(!modal)
  }

  const handleBolgeClick = (arg) => {
    const bolge = arg

    setBolge({
      id: bolge.id,
      bolge_adi: bolge.bolge_adi,
      bolge_description: bolge.bolge_description,
      bolge_kod: bolge.bolge_kod
    })

    setIsEdit(true)

    toggle()
  }
  const handleNewBolgeClick = () => {
    setBolge({})
    setIsEdit(false)
    toggle()
  }
  //delete bolge
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = (bolge) => {
    setBolge(bolge)
    setDeleteModal(true)
  }

  const handleDeleteBolge = () => {
    if (bolge && bolge.id) {
      dispatch(onDeleteBolge(bolge.id))
    }
    setDeleteModal(false)
  }


  const columns = useMemo(
    () => [
      {
        header: "#",
        id: "#",
        cell: (cellProps) => (
          <p className="text-dark mb-2">
            {cellProps.row.index + 1}
          </p>

        ),
        enableColumnFilter: false,
        enableSorting: true
      },
      {
        header: "Bölge Kodu",
        accessorKey: "bolge_kod",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => (
          <>
            <h5 className="text-truncate font-size-14">
              <p
                className="text-dark mb-0"
              >
                {cellProps.row.original.bolge_kod}
              </p>
            </h5>
          </>
        )
      },
      {
        header: "Bölge Adı",
        accessorKey: "bolge_adi",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => (
          <>
            <h5 className="text-truncate font-size-14">
              <p
                className="text-dark mb-0"
              >
                {cellProps.row.original.bolge_adi}
              </p>
            </h5>
          </>
        )
      },
      {
        header: "Bölge Açıklaması",
        accessorKey: "bolge_description",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => (
          <>
            <h5 className="text-truncate font-size-14">
              <p className="text-muted mb-0">
                {cellProps.row.original.bolge_description}
              </p>
            </h5>
          </>
        )
      },
      {
        header: "İşlemler",
        accessorKey: "actions",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps) => (
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => {
                const bolge = cellProps.row.original
                handleBolgeClick(bolge)
              }}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => {
                const bolgeData = cellProps.row.original
                onClickDelete(bolgeData)
              }}>
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
            </Link>
          </div>
        )
      }
    ],
    []
  )

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteBolge}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <ToastContainer transition={Slide} />
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("")}
            breadcrumbItem={props.t("Bölgeler")}
          />
          {loading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {bolgeler &&
                      <TableContainer
                        columns={columns}
                        data={bolgeler}
                        isGlobalFilter={true}
                        isAddButton={true}
                        isPagination={true}
                        isCustomPageSize={true}
                        handleUserClick={handleNewBolgeClick}
                        SearchPlaceholder="Ara"
                        buttonClass={null}
                        buttonName="Yeni Ekle"
                        tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                        theadClass="table-light"
                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                        pagination="pagination"
                      />}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {isEdit ? "Bölgeyi Düzenle" : "Bölge Ekle"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col xs={12}>
                    <div className="mb-3">
                      <Label>Bölge Kodu</Label>
                      <Input
                        name="bolge_kod"
                        type="number"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.bolge_kod || ""}
                        invalid={
                          validation.touched.bolge_kod && validation.errors.bolge_kod
                            ? true
                            : false
                        }
                      />
                      {validation.touched.bolge_kod && validation.errors.bolge_kod ? (
                        <FormFeedback type="invalid">
                          {validation.errors.bolge_kod}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Bölge Adı</Label>
                      <Input
                        name="bolge_adi"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.bolge_adi || ""}
                        invalid={
                          validation.touched.bolge_adi && validation.errors.bolge_adi
                            ? true
                            : false
                        }
                      />
                      {validation.touched.bolge_adi && validation.errors.bolge_adi ? (
                        <FormFeedback type="invalid">
                          {validation.errors.bolge_adi}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label>Bölge Açıklaması</Label>
                      <Input
                        name="bolge_description"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.bolge_description || ""}
                        invalid={
                          validation.touched.bolge_description &&
                          validation.errors.bolge_description
                            ? true
                            : false
                        }
                      />
                      {validation.touched.bolge_description &&
                      validation.errors.bolge_description ? (
                        <FormFeedback type="invalid">
                          {validation.errors.bolge_description}
                        </FormFeedback>
                      ) : null}
                    </div>

                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        color="primary"
                        type="submit"
                        className="save-user"
                      >
                        Kaydet
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

Bolgeler.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Bolgeler)
