import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import RulesModal from "./KurallarComponent"

//i18n
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"

import {
  listSegmentsRequest as onListSegments,
  addSegmentRequest as onAddSegment,
  updateSegmentRequest as onUpdateSegment,
  deleteSegmentRequest as onDeleteSegment
} from "../../store/segmentler/actions"
import * as Yup from "yup"
import { useFormik } from "formik"
import { createSelector } from "reselect"
import DeleteModal from "../../components/Common/DeleteModal"
import { Slide, toast, ToastContainer } from "react-toastify"
import Spinners from "../../components/Common/Spinner"
import TableContainer from "../../components/Common/TableContainer"
import { isEmpty } from "lodash"
import { parseRules, rulesGenerateDescription } from "../../helpers/utils"
import { listPolicelerRequest } from "../../store/policeTipleri/actions"

const SegmentStatus = ({ status }) => {
  return status ? (
    <Badge className="bg-success">Aktif</Badge>
  ) : (
    <Badge className="bg-danger">Pasif</Badge>
  )
}

const Segmentler = (props) => {
  //meta title
  document.title = "Segmentler | ZUZZUU"
  const dispatch = useDispatch()

  const [segment, setSegment] = useState()
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (segment && segment.id) || "",
      segment_name: (segment && segment.segment_name) || "",
      segment_description: (segment && segment.segment_description) || "",
      segment_rules: (segment && segment.segment_rules) || "",
      segment_picture: "", // Base64 image
      segment_status: (segment && segment.segment_status) || true
    },
    validationSchema: Yup.object({
      segment_name: Yup.string().required("Lütfen Segment Adını Giriniz"),
      segment_rules: Yup.string().nullable(""),
      segment_description: Yup.string().required("Lütfen Açıklamayı Giriniz"),
      segment_status: Yup.boolean().required()
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateSegment = {
          id: segment.id,
          ...values
        }

        // update segment
        dispatch(onUpdateSegment(updateSegment))
      } else {
        const { id, ...newSegment } = values
        dispatch(onAddSegment(newSegment))
      }
      toggle()
    }
  })

  const selectSegments = createSelector(
    (state) => state.Segmentler,
    (Segments) => ({
      segments: Segments.segments,
      loading: Segments.loading,
      error: Segments.error
    })
  )
  const { policeler } = useSelector(state => state.Policeler)
  const { segments, loading, error } = useSelector(selectSegments)

  const [isLoading, setLoading] = useState(loading);

  const [modal, setModal] = useState(false)
  const [rulesModalOpen, setRulesModalOpen] = useState(false)

  const toggleRulesModal = () => {
    setRulesModalOpen(!rulesModalOpen)
  }
  const handleRulesChange = (newRules) => {
    validation.setFieldValue("segment_rules", newRules)
  }
  const [isEdit, setIsEdit] = useState(false)

  useEffect(() => {
    dispatch(onListSegments())
    dispatch(listPolicelerRequest())
    setIsEdit(false)
  }, [dispatch])

  useEffect(() => {
    setIsEdit(false)
  }, [segments])
  useEffect(() => {
    if (error) {
      toast.error(error)
    }
  }, [error])

  const toggle = () => {
    setModal(!modal)
  }

  useEffect(()=>{
    if (!modal){
      setRulesModalOpen(false);
    }
  },[modal])

  const handleSegmentClick = (arg) => {
    const segment = arg

    setSegment({
      id: segment.id,
      segment_name: segment.segment_name,
      segment_description: segment.segment_description,
      segment_picture: segment.segment_picture,
      segment_rules: segment.segment_rules,
      segment_status: segment.segment_status
    })

    setIsEdit(true)

    toggle()
  }
  const handleNewSegmentClick = () => {
    setSegment({})
    setIsEdit(false)
    toggle()
  }
  //delete segment
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = (segment) => {
    setSegment(segment)
    setDeleteModal(true)
  }

  const handleDeleteSegment = () => {
    if (segment && segment.id) {
      dispatch(onDeleteSegment(segment.id))
    }
    setDeleteModal(false)
  }


  const columns = useMemo(
    () => [
      {
        header: "#",
        id: "#",
        cell: (cellProps) => (
          <p className="text-dark mb-2">
            {cellProps.row.index + 1}
          </p>

        ),
        enableColumnFilter: false,
        enableSorting: true
      },
      {
        header: "Segment Adı",
        accessorKey: "segment_name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => (
          <>
            <h5 className="text-truncate font-size-14">
              <p
                className="text-dark mb-0"
              >
                {cellProps.row.original.segment_name}
              </p>
            </h5>
          </>
        )
      },
      {
        header: "Segment Açıklama",
        accessorKey: "segment_description",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => (
          <>
            <h5 className="text-truncate font-size-14">
              <p className="text-muted mb-0">
                {cellProps.row.original.segment_description}
              </p>
            </h5>
          </>
        )
      },
      {
        header: "Kurallar",
        accessorKey: "segment_rules",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps) => (
          <>
            {cellProps.row.original.segment_rules?.split(";").map((rule, index) => (
              <p key={index} className="text-muted mb-0">{rule}</p>
            ))}
          </>
        )
      },
      {
        header: "Kural Açıklama",
        accessorKey: "segment_rules_1",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps) => (
          <div className="w-sm">
            {rulesGenerateDescription(cellProps.row.original.segment_rules)?.split(".").map((rule, index) => (
              <p key={index} className="text-dark mb-0">{rule}</p>
            ))}
          </div>
        )
      },
      {
        header: "Durum",
        accessorKey: "segment_status",
        cell: (cellProps) => (
          <SegmentStatus status={cellProps.row.original.segment_status} />
        ),
        enableColumnFilter: false,
        enableSorting: true
      },
      {
        header: "İşlemler",
        accessorKey: "actions",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps) => (
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => {
                const segment = cellProps.row.original
                handleSegmentClick(segment)
              }}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => {
                const segmentData = cellProps.row.original
                onClickDelete(segmentData)
              }}>
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
            </Link>
          </div>
        )
      }
    ],
    []
  )

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteSegment}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <ToastContainer transition={Slide} />
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("")}
            breadcrumbItem={props.t("Segmentler")}
          />
          {loading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {segments &&
                      <TableContainer
                        columns={columns}
                        data={segments}
                        isGlobalFilter={true}
                        isAddButton={true}
                        isPagination={true}
                        isCustomPageSize={true}
                        handleUserClick={handleNewSegmentClick}
                        SearchPlaceholder="Ara"
                        buttonClass={null}
                        buttonName="Yeni Ekle"
                        tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                        theadClass="table-light"
                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                        pagination="pagination"
                      />}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {isEdit ? "Segmenti Düzenle" : "Segment Ekle"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col xs={12}>
                    <Input
                      type="hidden"
                      value={validation.values.img || ""}
                      name="img"
                    />
                    <div className="mb-3">
                      <Label>Segment Adı</Label>
                      <Input
                        name="segment_name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.segment_name || ""}
                        invalid={
                          validation.touched.segment_name && validation.errors.segment_name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.segment_name && validation.errors.segment_name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.segment_name}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label>Segment Açıklaması</Label>
                      <Input
                        name="segment_description"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.segment_description || ""}
                        invalid={
                          validation.touched.segment_description &&
                          validation.errors.segment_description
                            ? true
                            : false
                        }
                      />
                      {validation.touched.segment_description &&
                      validation.errors.segment_description ? (
                        <FormFeedback type="invalid">
                          {validation.errors.segment_description}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div
                      className="mb-3">
                      <Label className="me-2">Segment Kuralı</Label>
                      <div className=" d-flex gap-2"><Input
                        className="w-75"
                        disabled={true}
                        readOnly={true}
                        name="segment_rules"
                        type="textarea"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.segment_rules || ""}
                        invalid={
                          validation.touched.segment_rules &&
                          validation.errors.segment_rules
                            ? true
                            : false
                        }
                      />
                        <button
                          type="button"
                          className="btn btn-primary w-25" // Style the button as a link
                          onClick={toggleRulesModal} // Open the rules modal on button click
                        >
                          Kural Düzenle
                        </button>
                      </div>
                    </div>
                    <RulesModal
                      segmentName={validation.values.segment_name}
                      policeler={policeler}
                      isOpen={rulesModalOpen}
                      toggleModal={toggleRulesModal}
                      segmentRules={validation.values.segment_rules}
                      onUpdateRulesInput={handleRulesChange}
                    />

                    <div className="mb-3">
                      <Label>Durum</Label>
                      <Input
                        type="select"
                        name="segment_status"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.segment_status}
                        invalid={
                          validation.touched.segment_status &&
                          validation.errors.segment_status
                            ? true
                            : false
                        }
                      >
                        <option value={true}>Aktif</option>
                        <option value={false}>Pasif</option>
                      </Input>
                      {validation.touched.segment_status &&
                      validation.errors.segment_status ? (
                        <FormFeedback type="invalid">
                          {validation.errors.segment_status}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        color="primary"
                        type="submit"
                        className="save-user"
                      >
                        Kaydet
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

Segmentler.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Segmentler)
