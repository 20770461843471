import PropTypes from "prop-types"
import React, { useState, useMemo, useCallback, useEffect } from "react"
import {
  Button, Col, Container, Input, Label, Modal, ModalBody, ModalHeader, Row
} from "reactstrap"
import Select from "react-select"
import { selectStyles } from "../../components/Common/SelectStyles"
import { parseRules } from "../../helpers/utils"
import policeKuralBaseObje from "../../assets/data/policeKuralBaseObje.json"

const KurallarComponent = ({ isOpen, toggleModal, segmentName, policeler, segmentRules, onUpdateRulesInput }) => {

  const [updatedRules, setUpdatedRules] = useState(segmentRules)
  const [policeOptions, setPoliceOptions] = useState([])

  useEffect(() => {
    debugger
    const transformedPoliceOptions = policeler.map(item => ({
      value: item.police_name,
      label: item.police_description,
      partition_type_code_desc: item.partition_type_code_desc
    }))
    setPoliceOptions(transformedPoliceOptions)
  }, [policeler])

  useEffect(() => {
    if (!isOpen) {
      setUpdatedRules("")
    } else {
      setUpdatedRules(segmentRules)
    }
  }, [isOpen])


  const operators = useMemo(() => [{ label: "VE", value: "&&" }, { label: "VEYA", value: "||" }], [])

  const baseData = useMemo(() => (policeKuralBaseObje), [])
  const [selectedPoliceler, setSelectedPoliceler] = useState({ M: [], P: [] }) // M segment için seçilen poliçelerin listesi
  const [formData, setFormData] = useState({ M: [], P: [] })

  useEffect(() => {
    const currentMPolicies = formData["M"].map(data => data.police)
    const currentPPolicies = formData["P"].map(data => data.police)

    setSelectedPoliceler(prevSelectedPoliceler => ({
      M: policeOptions.filter(option => !currentMPolicies.includes(option.value)),
      P: policeOptions.filter(option => !currentPPolicies.includes(option.value))
    }))
  }, [formData])

  useEffect(() => {
    const parsedRules = parseRules(updatedRules)
    setFormData({
      M: parsedRules.M, P: parsedRules.P
    })
  }, [updatedRules])


  const handleInputChange = useCallback((event, segmentType, index, field) => {
    const { value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [segmentType]: prevFormData[segmentType].map((data, i) => i === index ? { ...data, [field]: value } : data)
    }))
  }, [])

  const handleSelectChange = useCallback((selectedOption, segmentType, index, field) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [segmentType]: prevFormData[segmentType].map((data, i) => i === index ? {
        ...data,
        [field]: selectedOption?.value || ""
      } : data)
    }))
  }, [])

  const addNewRule = useCallback((segmentType) => {
    setFormData((prevFormData) => ({
      ...prevFormData, [segmentType]: [...prevFormData[segmentType], baseData]
    }))
  }, [])

  const generateRule = useCallback(() => {
    const mRules = formData.M
      .filter((data) => data.police.trim() !== "") // Filter out entries with empty police
      .map((data, index, array) => {
        if (index === array.length - 1) { // Check if it's the last object
          return `${data.police}_N=${data.adet}_PC${data.operator}${data.police}_RN=${data.tutar}_SR`
        } else {
          return `${data.police}_N=${data.adet}_PC${data.operator}${data.police}_RN=${data.tutar}_SR${data.araOperator}`
        }
      })
      .join("")

    const pRules = formData.P
      .filter((data) => data.police.trim() !== "") // Filter out entries with empty police
      .map((data) => `P:${data.police}_SR=${data.tutar}${data.operator}${data.police}_PC=${data.adet},B=%${data.prim}`)
      .join(";")

    const generatedMRule = mRules.length > 0 ? `M:${mRules};` : ""
    const generatedPRule = pRules.length > 0 ? `${pRules};` : ""

    onUpdateRulesInput(`${generatedMRule}${generatedPRule}`)
    toggleModal()
  }, [formData.M, formData.P])


  return (<Modal className="w-100" isOpen={isOpen} toggle={toggleModal} size="lg">
    <ModalBody className="w-100">
      <ModalHeader>
        {segmentName} Segment Kuralları
      </ModalHeader>
      <React.Fragment>
        <Container fluid>
          <div className=" p-3 w-100">
            {["M", "P"].map((segmentType) => (<div key={segmentType} className="my-2">
              <Row className="d-flex justify-content-between align-items-center">
                <Col sm={2}>
                  <h6>{segmentType === "M" ? "Zorunlu Kurallar" : "Poliçe Kuralları"}</h6>
                </Col>
                <Col sm={2} className="d-flex justify-content-end">
                  <Button
                    className="btn btn-sm btn-primary text-white bg-primary rounded-2"
                    onClick={() => addNewRule(segmentType)}
                  >
                    <i className="fa fa-plus-circle"></i> Yeni Kural
                  </Button>
                </Col>
              </Row>
              <Row>
                <div className="border-bottom my-3 border-secondary"></div>
              </Row>
              {formData[segmentType].map((data, index) => (<Row key={index}>
                <div style={{
                  border: "1px solid rgb(238 240 249 / 69%)",
                  borderRadius: "10px",
                  boxShadow: "inset rgb(89 89 89) -5px 2px 5px 1px"
                }} className="d-flex my-2 gap-1 p-3">
                  <Col sm={4}>
                    <div className="mb-3">
                      <Label for={`policySelect${index}`}>Poliçe</Label>
                      <Select
                        isClearable={true}
                        styles={selectStyles}
                        value={policeOptions.find((p) => p.value === data.police) || ""}
                        id={`policySelect${index}`}
                        options={selectedPoliceler[segmentType]}
                        onChange={(selectedOption) => handleSelectChange(selectedOption, segmentType, index, "police")}
                        placeholder="Poliçe Tipi"
                      />
                    </div>
                  </Col>
                  <Col sm={2}>
                    <div className="mb-3">
                      <Label for={`policyCountInput${index}`}>Satış Adedi</Label>
                      <Input
                        type="number"
                        min={0}
                        id={`policyCountInput${index}`}
                        value={data.adet}
                        onChange={(e) => handleInputChange(e, segmentType, index, "adet")}
                      />
                    </div>
                  </Col>
                  <Col sm={2}>
                    <div className="mb-3">
                      <Label for={`operatorSelect${index}`}>Operatör</Label>
                      <Select
                        styles={selectStyles}
                        value={operators.find((o) => o.value === data.operator) || ""}
                        id={`operatorSelect${index}`}
                        options={operators}
                        onChange={(selectedOption) => handleSelectChange(selectedOption, segmentType, index, "operator")}
                      />
                    </div>
                  </Col>
                  <Col sm={2}>
                    <div className="mb-3">
                      <Label for={`tutarInput${index}`}>Satış Tutarı (₺)</Label>
                      <Input
                        type="number"
                        min={0}
                        id={`tutarInput${index}`}
                        value={data.tutar}
                        onChange={(e) => handleInputChange(e, segmentType, index, "tutar")}
                      />
                    </div>
                  </Col>
                  {segmentType === "P" && (<Col sm={1}>
                    <div className="mb-3">
                      <Label for={`pPrimInput${index}`}>Prim (%)</Label>
                      <Input
                        type="number"
                        min={0}
                        id={`pPrimInput${index}`}
                        value={data.prim}
                        onChange={(e) => handleInputChange(e, segmentType, index, "prim")}
                      />
                    </div>
                  </Col>)}
                </div>
                {segmentType === "M" && index !== formData.M.length - 1 && (
                  <Row className="d-flex justify-content-center">
                    <Col sm={2}>
                      <div className="mb-3">
                        <Label for={`araOperatorSelect${index}`}>Operatör</Label>
                        <Select
                          styles={selectStyles}
                          value={operators.find((o) => o.value === data.araOperator) || "&&"}
                          id={`araOperatorSelect${index}`}
                          options={operators}
                          onChange={(selectedOption) => handleSelectChange(selectedOption, segmentType, index, "araOperator")}
                        />
                      </div>
                    </Col>
                  </Row>)}

              </Row>))}
            </div>))}
          </div>
          <Row className="d-flex justify-content-end">
            <Col className="d-flex justify-content-end">
              <Button color="primary" className="my-2" onClick={generateRule}>
                Kural Oluştur
              </Button>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    </ModalBody>
  </Modal>)
}

KurallarComponent.propTypes = {
  t: PropTypes.any
}

export default KurallarComponent
