import React from "react"
import {Navigate} from "react-router-dom"

// Profile
//import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"


import UserProfile from "../pages/Authentication/user-profile"
import Dashboard from "../pages/Dashboard";
import BolgeMudurleri from "../pages/BolgeMudurleri";
import Asu from "../pages/ASU";
import Acenteler from "../pages/SKSListesi";
import Bolgeler from "../pages/Bolgeler";
import SegmentKurallari from "../pages/Segmentler";
import PoliceTipleri from "../pages/PoliceTipleri"
import SatilanPoliceler from "../pages/SatilanPoliceler"
import PoliceSatisVerileri from "../pages/PoliceSatisVerileri"
import AcenteVerileri from "../pages/AcenteVerileri"
import ASUVerileri from "../pages/ASUVerileri"
import BMVerileri from "../pages/BMVerileri"

const authProtectedRoutes = [
    {path: "/", component: <Dashboard/>},
    // //profile
    {path: "/profile", component: <UserProfile/>},

    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    {
        path: "/",
        exact: true,
        component: <Navigate to="/"/>
    },
    {path: "/bolge-mudurleri", component: <BolgeMudurleri/>},
    {path: "/asu", component: <Asu/>},
    {path: "/segment-kurallari", component: <SegmentKurallari/>},
    {path: "/sks-listesi", component: <Acenteler/>},
    {path: "/bolgeler", component: <Bolgeler/>},
    {path: "/police-tipleri", component: <PoliceTipleri/>},
    {path: "/police-satis-verileri", component: <PoliceSatisVerileri/>},
    {path: "/acente-verileri", component: <AcenteVerileri/>},
    {path: "/asu-verileri", component: <ASUVerileri/>},
    {path: "/bm-verileri", component: <BMVerileri/>},
    {path: "/satilan-policeler", component: <SatilanPoliceler/>}

]

const publicRoutes = [
    {path: "/login", component: <Login/>},
    {path: "/logout", component: <Logout/>},
]

export {authProtectedRoutes, publicRoutes}
