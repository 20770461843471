import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { listAcenteLerRequest as onListAcenteler } from "../../store/acenteler/actions"
import { Slide, toast, ToastContainer } from "react-toastify"
import Spinners from "../../components/Common/Spinner"

import {
  listSatilanPolicelerRequest
} from "../../store/satilanPoliceler/actions"
import moment from "moment"
import SSTableContainer from "../../components/Common/SSTableContainer"
import { selectStyles } from "../../components/Common/SelectStyles"
import Select from "react-select"

const SatilanPoliceler = (props) => {
  //meta title
  document.title = "Satılan Poliçeler | ZUZZUU"
  const dispatch = useDispatch()
  const [police, setPolice] = useState({})
  const [page, setPage] = useState(0)
  const [selectedAgency, setSelectAgency] = useState(null)
  const acenteler = useSelector((state) => state.Acenteler.acenteler)


  const { satilanPoliceler, loading, error } = useSelector(state => state.SatilanPoliceler)

  const [isLoading, setLoading] = useState(loading)

  const [modal, setModal] = useState(false)

  useEffect(() => {
      if (acenteler.length === 0)
        dispatch(onListAcenteler())
    }
    , [dispatch])

  const handlePageChange = (currentPage) => {
    setPage(currentPage)
  }
  useEffect(() => {
    dispatch(listSatilanPolicelerRequest({
      "page": page + 1,
      "partajNo": selectedAgency ? selectedAgency.acente_partajno : null
    }))
  }, [page, selectedAgency])

  useEffect(() => {
    if (error) {
      toast.error(error)
    }
  }, [error])

  const toggle = () => {
    setModal(!modal)
  }

  const showDetail = (detailData) => {
    setPolice(detailData)
    setModal(true)
  }
  const keys = [
    "yil",
    "period",
    "acente_numarasi",
    "prim",
    "brut_prim",
    "police_baslangic_tarihi",
    "police_tanzim_tarihi",
    "police_bitis_tarihi",
    "yenileme_yeni_is",
    "brans",
    "brans_desc",
    "alt_brans_id",
    "alt_brans_desc",
    "urun_kodu",
    "urun_kodu_desc",
    "partition_type_code",
    "partition_type_code_desc",
    "urun_paket_desc",
    "trafik_pool_nonpool",
    "oip_tarihi",
    "riziko_cinsi",
    "riziko_cinsi_desc",
    "risk_type",
    "konut_riziko_konumu",
    "riziko_il",
    "riziko_ilce",
    "arac_plakasi",
    "arac_marka",
    "arac_model",
    "police_indirim",
    "contract_id",
    "bes_flag",
    "geldigi_sirket_kodu",
    "son_degisiklik_kod",
    "son_degisiklik_desc",
    "grup",
    "mis",
    "update_period",
    "sirket_kodu",
    "kampanya_kodu",
    "minibes"
  ]

  const turkishTitles = {
    "yil": "Yıl",
    "period": "Dönem",
    "acente_numarasi": "Acente",
    "prim": "Prim",
    "brut_prim": "Brüt Prim",
    "police_tanzim_tarihi": "Poliçe Tanzim Tarihi",
    "police_baslangic_tarihi": "Poliçe Başlangıç Tarihi",
    "police_bitis_tarihi": "Poliçe Bitiş Tarihi",
    "yenileme_yeni_is": "Yenileme / Yeni İş",
    "brans": "Branş",
    "brans_desc": "Branş Açıklama",
    "alt_brans_id": "Alt Branş ID",
    "alt_brans_desc": "Alt Branş Açıklama",
    "urun_kodu": "Ürün Kodu",
    "urun_kodu_desc": "Ürün Kodu Açıklama",
    "partition_type_code": "Bölüm Tip Kodu",
    "partition_type_code_desc": "Bölüm Tip Kodu Açıklama",
    "urun_paket_desc": "Ürün Paket Açıklama",
    "trafik_pool_nonpool": "Trafik Pool/Nonpool",
    "oip_tarihi": "OIP Tarihi",
    "riziko_cinsi": "Risk Cinsi",
    "riziko_cinsi_desc": "Risk Cinsi Açıklama",
    "risk_type": "Risk Türü",
    "konut_riziko_konumu": "Konut Risk Konumu",
    "riziko_il": "Risk İl",
    "riziko_ilce": "Risk İlçe",
    "arac_plakasi": "Araç Plakası",
    "arac_marka": "Araç Markası",
    "arac_model": "Araç Modeli",
    "police_indirim": "Poliçe İndirim",
    "contract_id": "Sözleşme ID",
    "bes_flag": "BES Flag",
    "geldigi_sirket_kodu": "Geldiği Şirket Kodu",
    "son_degisiklik_kod": "Son Değişiklik Kodu",
    "son_degisiklik_desc": "Son Değişiklik Açıklama",
    "grup": "Grup",
    "mis": "MIS",
    "update_period": "Güncelleme Dönemi",
    "sirket_kodu": "Şirket Kodu",
    "kampanya_kodu": "Kampanya Kodu",

    "minibes": "Minibes"
  }

  const columns = useMemo(
    () => [
      {
        header: "#",
        id: "#",
        cell: (cellProps) => (
          <p className="text-dark mb-2">
            {(page * 100) + cellProps.row.index + 1}
          </p>

        ),
        enableColumnFilter: false,
        enableSorting: true
      },
      {
        header: "Periyot",
        accessorKey: "period",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => (
          <>
            <h5 className="text-truncate font-size-14">
              <p className="text-dark mb-0">
                {cellProps.row.original.period}
              </p>
            </h5>
          </>
        )
      },
      {
        header: "Branş",
        accessorKey: "brans_desc",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => (
          <>
            <h5 className="text-truncate font-size-14">
              <p className="text-muted mb-0">
                {cellProps.row.original.brans_desc}
              </p>
            </h5>
          </>
        )
      },
      {
        header: "Alt Branş",
        accessorKey: "alt_brans_desc",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => (
          <>
            <h5 className="text-truncate font-size-14">
              <p className="text-muted mb-0">
                {cellProps.row.original.alt_brans_desc}
              </p>
            </h5>
          </>
        )
      },
      {
        header: "Ürün Kodu Açıklaması",
        accessorKey: "urun_kodu_desc",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => (
          <>
            <h5 className="text-truncate font-size-14">
              <p className="text-muted mb-0">
                {cellProps.row.original.urun_kodu_desc}
              </p>
            </h5>
          </>
        )
      },
      {
        header: "Prim",
        accessorKey: "prim",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => (
          <>
            <h5 className="text-truncate font-size-14">
              <p className="text-muted mb-0">
                {cellProps.row.original.prim || ""}
              </p>
            </h5>
          </>
        )
      },
      {
        header: "Brüt Prim",
        accessorKey: "brut_prim",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => (
          <>
            <h5 className="text-truncate font-size-14">
              <p className="text-muted mb-0">
                {cellProps.row.original.brut_prim || ""}
              </p>
            </h5>
          </>
        )
      },

      {
        header: "İşlemler",
        accessorKey: "actions",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps) => (
          <div className="d-flex">
            <Link
              to="#"
              className="text-primary"
              onClick={() => {
                showDetail(cellProps.row.original)
              }}
            >
              <i className="mdi mdi-eye font-size-18" id="edittooltip" />
            </Link>
          </div>
        )
      }
    ],
    [page]
  )


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <ToastContainer transition={Slide} />
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("")}
            breadcrumbItem={props.t("Satılan Poliçeler")}
          />
          {loading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {(satilanPoliceler && acenteler) &&
                      <SSTableContainer
                        childComponent={
                          <Col sm={4}>
                            <Select
                              isClearable={true}
                              name="selectedAgency"
                              placeholder="Tüm Acenteler"
                              onChange={(value) => {
                                setSelectAgency(value)
                                setPage(0);
                              }}
                              value={selectedAgency}
                              options={acenteler}
                              styles={selectStyles}
                              getOptionValue={item => item.id}
                              getOptionLabel={item => `${item.acente_partajno}-${item.acente_unvan}`}
                            /></Col>}
                        onPageChange={handlePageChange}
                        selectedPage={page}
                        dataLength={satilanPoliceler[0]}
                        showRecordSizes={[100]}
                        columns={columns}
                        data={satilanPoliceler}
                        isGlobalFilter={true}
                        isAddButton={false}
                        isPagination={true}
                        isCustomPageSize={false}
                        handleUserClick={null}
                        SearchPlaceholder="Ara"
                        buttonClass={null}
                        buttonName="Yeni Ekle"
                        tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                        theadClass="table-light"
                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                        pagination="pagination"
                      />}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {police && <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              Poliçe Detayı
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col xs={12}>
                  <div className="mb-3">
                    <table>
                      <tbody>
                      {keys.map((key, index) => (
                        <tr key={index}>
                          <td><p className="text-dark m-2">{turkishTitles[key]}: </p></td>
                          <td>
                            {key.includes("acente_numarasi") && police.acente_numarasi ? `(${police.acente_numarasi})-` + acenteler.find(a => a.acente_partajno === police.acente_numarasi).acente_unvan : key.includes("tarihi") && police[key] ? moment(police[key]).format("DD.MM.YYYY") : police[key] || "-"}
                          </td>
                        </tr>
                      ))}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </Modal>}
        </Container>
      </div>
    </React.Fragment>
  )
}

SatilanPoliceler.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(SatilanPoliceler)
