import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
  listAcenteLerRequest as onListAcenteler,
  addAcenteRequest as onAddAcente,
  updateAcenteRequest as onUpdateAcente,
  deleteAcenteRequest as onDeleteAcente
} from "../../store/acenteler/actions"
import * as Yup from "yup"
import { useFormik } from "formik"
import { createSelector } from "reselect"
import DeleteModal from "../../components/Common/DeleteModal"
import { Slide, toast, ToastContainer } from "react-toastify"
import TableContainer from "../../components/Common/TableContainer"
import cities from "../../assets/data/sehirler.json"
import { listBolgelerRequest as onListBolgeler } from "../../store/bolgeler/actions"
import {
  listSegmentsRequest as onListSegments
} from "../../store/segmentler/actions"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Spinners from "../../components/Common/Spinner"
import { withTranslation } from "react-i18next"
import { selectStyles } from "../../components/Common/SelectStyles"
import Select from "react-select"
import { listBmKullanicilarRequest } from "../../store/bmKullanicilar/actions"
import { listAsuKullanicilarRequest } from "../../store/asuKullanicilar/actions"


const SKSListesi = (props) => {
  document.title = "SKSListesi | ZUZZUU"
  const dispatch = useDispatch()

  const [acente, setAcente] = useState()


  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: (acente && acente.id) || "",
      acente_unvan: (acente && acente.acente_unvan) || "",
      acente_description: (acente && acente.acente_description) || "",
      acente_status: (acente && acente.acente_status) || true,
      //acente_partajno: (acente && acente.acente_partajno) || null,
      acente_gezegen: (acente && acente.acente_gezegen) || "",
      acente_bolgekod: (acente && acente.acente_bolgekod) || "",
     // acente_il: (acente && acente.acente_il) || "",
     //acente_bolge_yoneticisi: (acente && acente.acente_bolge_yoneticisi) || "",
     // acente_asu: (acente && acente.acente_asu) || "",
      acente_picture: (acente && acente.acente_picture) || "",
      acente_rules: (acente && acente.acente_rules) || ""
    },
    validationSchema: Yup.object({
      acente_unvan: Yup.string().required("Lütfen Acente Adını Giriniz"),
      acente_bolgekod: Yup.number().required("Lütfen Bölge Seçiniz"),
      acente_gezegen: Yup.number().required("Lütfen Segment Seçiniz"),
      acente_description: Yup.string().nullable("")
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateAcente = {
          id: acente.id,
          ...values
        }

        dispatch(onUpdateAcente(updateAcente))
      } else {
        const newAcente = {
          ...values
        }

        dispatch(onAddAcente(newAcente))
      }
      toggle()
    }
  })


  const { acenteler, loading, error } = useSelector((state) => state.Acenteler)
  const [isLoading, setLoading] = useState(loading)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  const { segments } = useSelector((state) => state.Segmentler)
  const { bolgeler } = useSelector((state) => state.Bolgeler)
  //const { asuKullanicilar } = useSelector(state => state.AsuKullanicilar)
 // const { bmKullanicilar } = useSelector(state => state.BmKullanicilar)

  useEffect(() => {
    dispatch(onListSegments())
    dispatch(onListBolgeler())
    //dispatch(listBmKullanicilarRequest())
    //dispatch(listAsuKullanicilarRequest())
    dispatch(onListAcenteler())
    setIsEdit(false)
  }, [dispatch])


  useEffect(() => {
    if (error) {
      toast.error(error)
    }
  }, [error])

  const toggle = () => {
    setModal(!modal)
  }
  const handleNewAcenteClick = () => {
    setAcente({})
    setIsEdit(false)
    toggle()
  }
  const handleAcenteClick = (arg) => {
    const acente = arg

    setAcente({
      ...acente
    })

    setIsEdit(true)

    toggle()
  }

  const onClickDelete = (acente) => {
    setAcente(acente)
    setDeleteModal(true)
  }

  const handleDeleteAcente = () => {
    if (acente && acente.id) {
      dispatch(onDeleteAcente(acente.id))
    }
    setDeleteModal(false)
  }

  const columns = useMemo(
    () => [
      {
        header: "#",
        id: "#",
        cell: (cellProps) => (
          <p className="text-dark mb-2">
            {cellProps.row.index + 1}
          </p>

        ),
        enableColumnFilter: false,
        enableSorting: true
      },

      {
        header: "SKS Adı",
        accessorKey: "acente_unvan",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => (
          <>
            <h5 className="text-truncate font-size-14">
              <p
                className="text-dark mb-0"
              >
                {cellProps.row.original.acente_unvan}
              </p>
            </h5>
            <p className="text-muted mb-0">
              {cellProps.row.original.acente_description}
            </p>
          </>
        )
      },
      {
        header: "Segment",
        accessorKey: "acente_gezegen",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps) => (
          <>
            <h5 className="text-truncate font-size-14">
              <p className="text-muted mb-0">
                {(cellProps.row.original.acente_gezegen && segments) ? segments.find(b => b.id === cellProps.row.original.acente_gezegen)?.segment_name : ""}
              </p>
            </h5>
          </>
        )
      },
      {
        header: "Bölge",
        accessorKey: "acente_bolgekod",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps) => (
          <>
            <h5 className="text-truncate font-size-14">
              <p className="text-muted mb-0">
                {(cellProps.row.original.acente_bolgekod && bolgeler) ? bolgeler.find(b => b.bolge_kod === cellProps.row.original.acente_bolgekod)?.bolge_adi : ""}
              </p>
            </h5>
          </>
        )

      },

      {
        header: "Kurallar",
        accessorKey: "acente_rules",
        enableColumnFilter: false,
        enableSorting: false
      },
      {
        header: "İşlemler",
        accessorKey: "actions",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps) => (
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => {
                const row = cellProps.row.original
                handleAcenteClick(row)
              }}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => {
                const row = cellProps.row.original
                onClickDelete(row)
              }}>
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
            </Link>
          </div>
        )
      }
    ],
    [acenteler, handleAcenteClick, onClickDelete, segments, bolgeler]
  )

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteAcente}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <ToastContainer transition={Slide} />
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("SKSİşlemleri")}
            breadcrumbItem={props.t("SKSListesi")}
          />
          {loading ? (
            <Spinners setLoading={setLoading} />
          ) : (<Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {error ? <p className="text-danger pt-2">{error}</p> :
                    <TableContainer
                      columns={columns}
                      data={acenteler}
                      isGlobalFilter={true}
                      isAddButton={true}
                      isPagination={true}
                      isCustomPageSize={true}
                      handleUserClick={handleNewAcenteClick}
                      SearchPlaceholder="Ara"
                      buttonClass={null}
                      buttonName="Yeni Ekle"
                      tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                      theadClass="table-light"
                      paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                      pagination="pagination"
                    />}
                </CardBody>
              </Card>
            </Col>
          </Row>)}
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {isEdit ? "SKS Düzenle" : "SKS Ekle"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col xs={12}>
                    {/*<div className="mb-3">
                      <Label>Partaj No</Label>
                      <Input
                        name="acente_partajno"
                        type="number"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.acente_partajno || ""}
                        invalid={
                          validation.touched.acente_partajno &&
                          validation.errors.acente_partajno
                            ? true
                            : false
                        }
                      />
                      {validation.touched.acente_partajno &&
                      validation.errors.acente_partajno ? (
                        <FormFeedback type="invalid">
                          {validation.errors.acente_partajno}
                        </FormFeedback>
                      ) : null}
                    </div>
                    */}
                    <div className="mb-3">
                      <Label>Unvan</Label>
                      <Input
                        name="acente_unvan"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.acente_unvan || ""}
                        invalid={
                          validation.touched.acente_unvan &&
                          validation.errors.acente_unvan
                            ? true
                            : false
                        }
                      />
                      {validation.touched.acente_unvan &&
                      validation.errors.acente_unvan ? (
                        <FormFeedback type="invalid">
                          {validation.errors.acente_unvan}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Açıklama</Label>
                      <Input
                        name="acente_description"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.acente_description || ""}
                        invalid={
                          validation.touched.acente_description &&
                          validation.errors.acente_description
                            ? true
                            : false
                        }
                      />
                      {validation.touched.acente_description &&
                      validation.errors.acente_description ? (
                        <FormFeedback type="invalid">
                          {validation.errors.acente_description}
                        </FormFeedback>
                      ) : null}
                    </div>

                    {/* Diğer metin girişi alanları buraya eklenebilir */}
                  </Col>
                  <Col xs={6}>
                    <div className="mb-3">
                      <Label>Bölge</Label>
                      <Select
                        name="acente_bolgekod"
                        onChange={(selectedOption) =>
                          validation.setFieldValue("acente_bolgekod", parseInt(selectedOption?.bolge_kod) || null)
                        }
                        value={validation.values.acente_bolgekod ? bolgeler.find(b => b.bolge_kod === validation.values.acente_bolgekod) : ""}
                        options={bolgeler}
                        placeholder="Seçiniz"
                        styles={selectStyles}
                        getOptionValue={item => item.bolge_kod}
                        getOptionLabel={item => item.bolge_adi}
                      />
                    </div>
                    {/*<div className="mb-3">
                      <Label>İl</Label>
                      <Select
                        name="acente_il"
                        placeholder="Seçiniz"
                        onChange={(selectedOption) =>
                          validation.setFieldValue("acente_il", selectedOption?.name || "")
                        }
                        onBlur={validation.handleBlur}
                        value={validation.values.acente_il ? cities.find(c => c.name.toLowerCase() === validation.values.acente_il.toLowerCase()) : ""}
                        options={cities}
                        getOptionLabel={city => city.name}
                        getOptionValue={city => city.name}
                        styles={selectStyles}
                      />
                    </div>*/}
                  </Col>
                  <Col xs={6}>
                    <div className="mb-3">
                      <Label>Segment(Gezegen)</Label>
                      <Select
                        name="acente_gezegen"
                        onChange={(selectedOption) =>
                          validation.setFieldValue("acente_gezegen", parseInt(selectedOption?.id) || null)
                        }
                        value={validation.values.acente_gezegen ? segments.find(s => s.id === validation.values.acente_gezegen) : ""}
                        options={segments}
                        placeholder="Seçiniz"
                        styles={selectStyles}
                        getOptionValue={item => item.id}
                        getOptionLabel={item => item.segment_name}
                      />
                    </div>

                  </Col>
                </Row>
                <Row>
                  {/*<Col xs={6}>
                    <div className="mb-3">
                      <Label>Bölge Müdürü</Label>
                      <Select
                        name="acente_bolge_yoneticisi"
                        onChange={(selectedOption) =>
                          validation.setFieldValue("acente_bolge_yoneticisi", selectedOption?.useruniqid || "")
                        }
                        value={validation.values.acente_bolge_yoneticisi ? bmKullanicilar.find(bm => bm.useruniqid === validation.values.acente_bolge_yoneticisi) : ""}
                        options={bmKullanicilar}
                        placeholder="Seçiniz"
                        styles={selectStyles}
                        getOptionValue={item => item.useruniqid}
                        getOptionLabel={item => item.ad + " " + item.soyad}
                      />
                    </div>

                  </Col>*/}
                  {/*<Col xs={6}>
                    <div className="mb-3">
                      <Label>ASU</Label>
                      <Select
                        name="acente_asu"
                        onChange={(selectedOption) =>
                          validation.setFieldValue("acente_asu", selectedOption?.useruniqid || "")
                        }
                        value={validation.values.acente_asu ? asuKullanicilar.find(asu => asu.useruniqid === validation.values.acente_asu) : ""}
                        options={asuKullanicilar}
                        placeholder="Seçiniz"
                        styles={selectStyles}
                        getOptionValue={item => item.useruniqid}
                        getOptionLabel={item => item.ad + " " + item.soyad}
                      />
                    </div>
                  </Col>*/}
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        color="primary"
                        type="submit"
                        className="save-user"
                      >
                        Kaydet
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>

        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

SKSListesi.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(SKSListesi)
