import { takeLatest, call, put } from "redux-saga/effects"
import * as types from "./actionTypes"
import * as api from "helpers/api_helper.js"
import { reverseSortById } from "../../helpers/utils"

function* listPolicelerSaga() {
  try {
    const dataList = yield call(api.listPoliceler)
    yield put({ type: types.LIST_POLICELER_SUCCESS, payload: reverseSortById(dataList) })
  } catch (error) {
    yield put({ type: types.LIST_POLICELER_FAILURE, payload: error.message })
  }
}

function* addPoliceSaga(action) {
  try {
    yield call(api.addPolice, action.payload)
    yield call(listPolicelerSaga)
  } catch (error) {
    yield put({ type: types.ADD_POLICE_FAILURE, payload: error.message })
  }
}

function* updatePoliceSaga(action) {
  try {
    const { updateData } = action.payload
    debugger;
    yield call(api.updatePolice, updateData.id, updateData)
    yield call(listPolicelerSaga)
  } catch (error) {
    yield put({ type: types.UPDATE_POLICE_FAILURE, payload: error.message })
  }
}

function* deletePoliceSaga(action) {
  try {
    const id = action.payload
    yield call(api.deletePolice, id)
    yield call(listPolicelerSaga)
  } catch (error) {
    yield put({ type: types.DELETE_POLICE_FAILURE, payload: error.message })
  }
}

export default function* asuKullanicilarSaga() {
  yield takeLatest(types.LIST_POLICELER_REQUEST, listPolicelerSaga)
  yield takeLatest(types.ADD_POLICE_REQUEST, addPoliceSaga)
  yield takeLatest(types.UPDATE_POLICE_REQUEST, updatePoliceSaga)
  yield takeLatest(types.DELETE_POLICE_REQUEST, deletePoliceSaga)
}
