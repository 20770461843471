import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input, InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"

import * as Yup from "yup"
import { useFormik } from "formik"
import sehirler from "../../assets/data/sehirler.json"
import DeleteModal from "../../components/Common/DeleteModal"
import { Slide, toast, ToastContainer } from "react-toastify"
import Spinners from "../../components/Common/Spinner"
import TableContainer from "../../components/Common/TableContainer"
import {
  addBmKullaniciRequest, deleteBmKullaniciRequest,
  listBmKullanicilarRequest,
  updateBmKullaniciRequest
} from "../../store/bmKullanicilar/actions"
import Select from "react-select"
import { listBolgelerRequest as onListBolgeler } from "../../store/bolgeler/actions"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

import flatpickrTr from "../../assets/data/flatpickr-Tr.json"
import { selectStyles } from "../../components/Common/SelectStyles"
import moment from "moment/moment"

const BolgeMudurleri = (props) => {
  //meta title
  document.title = "Bölge Müdürleri | ZUZZUU"
  const dispatch = useDispatch()
  const [kullanici, setKullanici] = useState()
  const [isEdit, setIsEdit] = useState(false)


  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      kullanici_adi: (kullanici && kullanici.kullanici_adi) || "",
      sifre: (kullanici && kullanici.sifre) || "",
      ad: (kullanici && kullanici.ad) || "",
      soyad: (kullanici && kullanici.soyad) || "",
      email: (kullanici && kullanici.email) || "",
      gsm: (kullanici && kullanici.gsm) || "",
      dogum_tarihi: (kullanici && kullanici.dogum_tarihi) || "",
      cinsiyet: (kullanici && kullanici.cinsiyet) || "",
      sehir: (kullanici && kullanici.sehir) || "",
      bolge: (kullanici && kullanici.bolge) || "",
      rol: (kullanici && kullanici.rol) || "BM",
      adres: (kullanici && kullanici.adres) || ""
    },
    validationSchema: Yup.object({
      ad: Yup.string().required("Lütfen Adınızı yazınız"),
      soyad: Yup.string().required("Lütfen Soyadınızı yazınız"),
      kullanici_adi: Yup.string().when("isEdit", {
        is: () => false, // Eğer isEdit false ise (yani, yeni bir kullanıcı ekleniyorsa)
        then: () => Yup.string().required("Lütfen Kullanıcı Adınızı yazınız"),
        otherwise: () => Yup.string() // Aksi takdirde, doğrulama yapma
      }),
      email: Yup.string().email("Lütfen geçerli bir email adresi yazınız"),
      gsm: Yup.string().matches(/^[0-9]{11}$/, "Geçerli bir cep telefonu numarası giriniz").required("Cep telefonu numarası zorunludur"),
      dogum_tarihi: Yup.string().nullable(""),
      cinsiyet: Yup.string().nullable(""),
      sehir: Yup.string().nullable(""),
      bolge: Yup.number().nullable("Bölge seçiniz"),
      rol: Yup.string().nullable(""),
      adres: Yup.string().nullable("")
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateKullanici = {
          useruniqid: kullanici.useruniqid,
          ...values
        }

        // update bolge
        dispatch(updateBmKullaniciRequest(updateKullanici))
      } else {
        const { useruniqid, ...newBmKullanici } = values
        dispatch(addBmKullaniciRequest(newBmKullanici))
      }
      toggle()
    }
  })

  const { bmKullanicilar, loading, error } = useSelector(state => state.BmKullanicilar)
  const { bolgeler } = useSelector(state => state.Bolgeler)

  const [isLoading, setLoading] = useState(loading)

  const [modal, setModal] = useState(false)

  useEffect(() => {
    dispatch(listBmKullanicilarRequest())
    setIsEdit(false)
  }, [dispatch])

  useEffect(() => {
    if (error) {
      toast.error(error)
    }
  }, [error])

  const toggle = () => {
    setModal(!modal)
  }
  useEffect(() => {
    if (modal) {
      dispatch(onListBolgeler())
    }
  }, [modal])

  const handleKullaniciClick = (arg) => {
    const kullanici = arg
    setKullanici({
      useruniqid: kullanici.useruniqid,
      kullanici_adi: kullanici.kullanici_adi,
      ad: kullanici.ad,
      soyad: kullanici.soyad,
      email: kullanici.email,
      gsm: kullanici.gsm,
      dogum_tarihi: kullanici.dogum_tarihi,
      cinsiyet: kullanici.cinsiyet,
      sehir: kullanici.sehir,
      bolge: kullanici.bolge,
      rol: kullanici.rol,
      adres: kullanici.adres
    })

    setIsEdit(true)

    toggle()
  }
  const handleNewKullaniciClick = () => {
    setKullanici({})
    setIsEdit(false)
    toggle()
  }
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = (kullaniciData) => {
    setKullanici(kullaniciData)
    setDeleteModal(true)
  }

  const handleDeleteRecord = () => {
    if (kullanici && kullanici.useruniqid) {
      dispatch(deleteBmKullaniciRequest(kullanici.useruniqid))
    }
    setDeleteModal(false)
  }


  const columns = useMemo(
    () => [
      {
        header: "#",
        cell: (cellProps) => (
          <div>{cellProps.row.index + 1}</div>
        ),
        enableColumnFilter: false,
        enableSorting: true
      },
      {
        header: "Kullanıcı Adı",
        accessorKey: "kullanici_adi",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => (
          <>
            <h5 className="text-truncate font-size-14">
              <p
                className="text-dark mb-0"
              >
                {cellProps.row.original.kullanici_adi}
              </p>
            </h5>
          </>
        )
      },
      {
        header: "Ad Soyad",
        accessorKey: "ad",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => (
          <>
            <h5 className="text-truncate font-size-14">
              <p
                className="text-dark mb-0"
              >
                {cellProps.row.original.ad + " " + cellProps.row.original.soyad}
              </p>
            </h5>
          </>
        )
      },
      {
        header: "Eposta",
        accessorKey: "email",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => (
          <>
            <h5 className="text-truncate font-size-14">
              <p className="text-muted mb-0">
                {cellProps.row.original.email}
              </p>
            </h5>
          </>
        )
      },
      {
        header: "Gsm",
        accessorKey: "gsm",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => (
          <>
            <h5 className="text-truncate font-size-14">
              <p className="text-muted mb-0">
                {cellProps.row.original.gsm}
              </p>
            </h5>
          </>
        )
      },
      {
        header: "Bölge",
        accessorKey: "bolge",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => (
          <>
            <h5 className="text-truncate font-size-14">
              <p className="text-muted mb-0">
                {cellProps.row.original.bolge ? bolgeler.find(b=>b.id.toString() === cellProps.row.original.bolge)?.bolge_adi :""}
              </p>
            </h5>
          </>
        )
      },
      {
        header: "Şehir",
        accessorKey: "sehir",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => (
          <>
            <h5 className="text-truncate font-size-14">
              <p className="text-muted mb-0">
                {cellProps.row.original.sehir}
              </p>
            </h5>
          </>
        )
      },
      {
        header: "İşlemler",
        accessorKey: "actions",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps) => (
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => {
                const rowData = cellProps.row.original
                handleKullaniciClick(rowData)
              }}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => {
                const rowData = cellProps.row.original
                onClickDelete(rowData)
              }}>
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
            </Link>
          </div>
        )
      }
    ],
    []
  )

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteRecord}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <ToastContainer transition={Slide} />
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("")}
            breadcrumbItem={props.t("Bölge Müdürleri")}
          />
          {loading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {bmKullanicilar &&
                      <TableContainer
                        columns={columns}
                        data={bmKullanicilar}
                        isGlobalFilter={true}
                        isAddButton={true}
                        isPagination={true}
                        isCustomPageSize={true}
                        handleUserClick={handleNewKullaniciClick}
                        SearchPlaceholder="Ara"
                        buttonClass={null}
                        buttonName="Yeni Ekle"
                        tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                        theadClass="table-light"
                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                        pagination="pagination"
                      />}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {isEdit ? "Kullanıcıyı Düzenle" : "Kullanıcı Ekle"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col xs={6}>
                    <div className="mb-3">
                      <Label>Kullanıcı Adı</Label>
                      <Input
                        autoComplete="off1"
                        name="kullanici_adi"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.kullanici_adi || ""}
                        invalid={
                          validation.touched.kullanici_adi && validation.errors.kullanici_adi
                            ? true
                            : false
                        }
                      />
                      {validation.touched.kullanici_adi && validation.errors.kullanici_adi ? (
                        <FormFeedback type="invalid">
                          {validation.errors.kullanici_adi}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="mb-3">
                      <Label>Şifre</Label>
                      <Input
                        autoComplete="off2"
                        name="sifre"
                        type="password"
                        required={!isEdit}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.sifre || ""}
                        invalid={
                          validation.touched.sifre && validation.errors.sifre
                            ? true
                            : false
                        }
                      />
                      {validation.touched.sifre && validation.errors.sifre ? (
                        <FormFeedback type="invalid">
                          {validation.errors.sifre}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <div className="mb-3">
                      <Label>Ad</Label>
                      <Input
                        name="ad"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.ad || ""}
                        invalid={
                          validation.touched.ad && validation.errors.ad
                            ? true
                            : false
                        }
                      />
                      {validation.touched.ad && validation.errors.ad ? (
                        <FormFeedback type="invalid">
                          {validation.errors.ad}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="mb-3">
                      <Label>Soyad</Label>
                      <Input
                        name="soyad"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.soyad || ""}
                        invalid={
                          validation.touched.soyad && validation.errors.soyad
                            ? true
                            : false
                        }
                      />
                      {validation.touched.soyad && validation.errors.soyad ? (
                        <FormFeedback type="invalid">
                          {validation.errors.soyad}
                        </FormFeedback>
                      ) : null}
                    </div>

                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <div className="mb-3">
                      <Label>E-mail</Label>
                      <Input
                        name="email"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                          validation.touched.email && validation.errors.email
                            ? true
                            : false
                        }
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">
                          {validation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="mb-3">
                      <Label>Gsm</Label>
                      <Input
                        name="gsm"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.gsm || ""}
                        invalid={
                          validation.touched.gsm && validation.errors.gsm
                            ? true
                            : false
                        }
                      />
                      {validation.touched.gsm && validation.errors.gsm ? (
                        <FormFeedback type="invalid">
                          {validation.errors.gsm}
                        </FormFeedback>
                      ) : null}
                    </div>

                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <div className="mb-3">
                      <Label>Bölge</Label>
                      {bolgeler && <Select
                        name="bolge"
                        placeholder="Seçiniz"
                        onChange={(selectedOption) =>
                          validation.setFieldValue("bolge", selectedOption?.id || "")
                        }
                        onBlur={validation.handleBlur}
                        value={bolgeler?.find(bolge => parseInt(bolge.id) === parseInt(validation.values.bolge))}
                        options={bolgeler}
                        getOptionLabel={bolge => bolge.bolge_adi}
                        getOptionValue={bolge => bolge.id}
                        styles={selectStyles}
                      />}
                      {validation.touched.bolge && validation.errors.bolge ? (
                        <FormFeedback type="invalid">
                          {validation.errors.bolge}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col xs={6}>
                    <div className="mb-3">
                      <Label>Şehir</Label>
                      {sehirler && <Select
                        name="sehir"
                        placeholder="Seçiniz"
                        options={sehirler}
                        onChange={(selectedOption) =>
                          validation.setFieldValue("sehir", selectedOption?.name || "")
                        }
                        onBlur={validation.handleBlur}
                        getOptionLabel={sehir => sehir.name}
                        getOptionValue={sehir => sehir.name}
                        value={sehirler?.find(sehir => sehir.name === validation.values.sehir)}
                        styles={selectStyles}
                      >
                      </Select>}
                      {validation.touched.sehir && validation.errors.sehir ? (
                        <FormFeedback type="invalid">
                          {validation.errors.sehir}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                </Row>
                <Row>
                  <Col xs={6}>
                    <div className="mb-3">
                      <Label>Doğum Tarihi</Label>
                      <InputGroup>
                        <Flatpickr
                          name="dogum_tarihi"
                          className="form-control d-block"
                          options={{
                            ...flatpickrTr,
                            // dateFormat: "Y-m-d" // Yıl, ay ve gün formatı
                            dateFormat: "d-m-Y"
                          }}
                          onChange={(date) => validation.setFieldValue("dogum_tarihi", moment(date[0]).format("DD MMMM, YYYY"))}
                          value={validation.values.dogum_tarihi || ""}
                        />

                      </InputGroup>
                      {validation.touched.dogum_tarihi && validation.errors.dogum_tarihi ? (
                        <FormFeedback type="invalid">
                          {validation.errors.dogum_tarihi}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col xs={6}>
                    <div className="mb-3">
                      <Label>Cinsiyet</Label>
                      <Select
                        name="cinsiyet"
                        placeholder="Seçiniz"
                        options={[
                          { value: "Kadın", label: "Kadın" },
                          { value: "Erkek", label: "Erkek" }
                        ]}
                        onChange={(selectedOption) => {
                          validation.setFieldValue("cinsiyet", selectedOption ? selectedOption.value : "")
                        }}
                        onBlur={validation.handleBlur}
                        value={[
                          { value: "Kadın", label: "Kadın" },
                          { value: "Erkek", label: "Erkek" }
                        ].find(c => c.value === validation.values.cinsiyet) || ""} // Doğru şekilde validation.values.cinsiyet'i kullanın
                        styles={selectStyles}
                      />
                      {validation.touched.cinsiyet && validation.errors.cinsiyet ? (
                        <FormFeedback type="invalid">
                          {validation.errors.cinsiyet}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Col xs={12}>
                  <div className="mb-3">
                    <Label>Adres</Label>
                    <Input
                      name="adres"
                      type="textarea"

                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.adres || ""}
                      invalid={
                        validation.touched.adres && validation.errors.adres
                          ? true
                          : false
                      }
                    />
                    {validation.touched.adres && validation.errors.adres ? (
                      <FormFeedback type="invalid">
                        {validation.errors.adres}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        color="primary"
                        type="submit"
                        className="save-user"
                      >
                        Kaydet
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

BolgeMudurleri.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(BolgeMudurleri)
