import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Button, Card, CardBody, Col, Collapse, Container, Form, FormGroup, Input, Label, Row } from "reactstrap"
import { listAcenteLerRequest as onListAcenteler } from "../../store/acenteler/actions"
import { listSegmentsRequest as onListSegments } from "../../store/segmentler/actions"
import { listBolgelerRequest as onListBolgeler } from "../../store/bolgeler/actions"
import { listBmKullanicilarRequest } from "../../store/bmKullanicilar/actions"
import { listAsuKullanicilarRequest } from "../../store/asuKullanicilar/actions"
import Select from "react-select"
import print from "ink-html"
import { selectStyles } from "../../components/Common/SelectStyles"
import { listPolicePrimRequest } from "../../store/acentePolicePrimHedef/actions"
import BarChart from "../../components/Apex/barchart"
import { Slide, ToastContainer } from "react-toastify"
import Spinners from "../../components/Common/Spinner"
import { checkKazanim, getTextColor, getUserAgencyPartajNo, getUserRole } from "../../helpers/utils"

const Dashboard = (props) => {
  document.title = "Acente Prim Hakediş | ZUZZUU"

  // State for select boxes and search input
  const [formData, setFormData] = useState({
    selectedSegment: null,
    selectedRegion: null,
    selectedManager: null,
    selectedASU: null,
    selectedAgency: [],
    searchInput: ""
  })

  // Retrieve data from Redux store
  const segments = useSelector((state) => state.Segmentler.segments)
  const bolgeler = useSelector((state) => state.Bolgeler.bolgeler)
  //const { asuKullanicilar } = useSelector(state => state.AsuKullanicilar)
  //const { bmKullanicilar } = useSelector(state => state.BmKullanicilar)
  const { policePrim, segmentDetail, loading, error } = useSelector(state => state.AcentePolicePrimHedef)
  const { loading: acenteLoading, acenteler } = useSelector((state) => state.Acenteler)
  const [isLoading, setLoading] = useState(loading)
  const [userAgency, setUserAgency] = useState([])
  const [totalPrim, setTotalPrim] = useState({})
  const dispatch = useDispatch()
  const userRole = getUserRole()
  const printableContentRef = useRef(null)

  useEffect(() => {
   // dispatch(listBmKullanicilarRequest())
   // dispatch(listAsuKullanicilarRequest())
    dispatch(onListSegments())
    dispatch(onListBolgeler())
    dispatch(onListAcenteler())
  }, [dispatch])


  useEffect(() => {
    setUserAgency(acenteler)
  }, [acenteler])


  const handleSelect = (value, field) => {
    setFormData({
      ...formData,
      [field]: value || null
    })
  }


  const handleSearchInputChange = (event) => {
    const { name, value } = event.target
    setFormData({
      ...formData,
      [name]: value
    })
  }
  const [collapse, setCollapse] = useState([])
  const showAgency = (agency, acente_id) => {
    if (!collapse.includes(acente_id)) {
      //handleSelect(agency, "selectedAgency")
      if (!policePrim || !policePrim[agency.acente_partajno]) {
        dispatch(listPolicePrimRequest({
          "partajNo": agency.acente_partajno
        }))
      }
      setCollapse([...collapse, acente_id])
    } else {
      const updatedCollapse = collapse.filter(id => id !== acente_id)
      setCollapse(updatedCollapse)
    }
  }

  // Handle search button click
  const handleSearch = () => {
    let filteredAcenteler = acenteler
    if (formData.selectedAgency.length > 0) {
      filteredAcenteler = filteredAcenteler.filter(a => formData.selectedAgency.includes(a))
    }
    if (formData.selectedSegment) {
      filteredAcenteler = filteredAcenteler.filter(a => a.acente_gezegen === formData.selectedSegment.id)
    }
    if (formData.selectedRegion) {
      filteredAcenteler = filteredAcenteler.filter(a => a.acente_bolgekod === formData.selectedRegion.bolge_kod)
    }
    if (formData.selectedManager) {
      filteredAcenteler = filteredAcenteler.filter(a => a.acente_bolge_yoneticisi === formData.selectedManager.useruniqid)
    }
    if (formData.selectedASU) {
      filteredAcenteler = filteredAcenteler.filter(a => a.acente_asu === formData.selectedASU.useruniqid)
    }

    setUserAgency(filteredAcenteler)
  }


  const handleSaveAsReport = () => {
    const printableContent = printableContentRef.current
    print(printableContent)
  }

  useEffect(() => {
    Object.keys(policePrim).forEach(partajNo => {
      let total = 0
      policePrim[partajNo].forEach(p => {
        const rule = [...segmentDetail[partajNo].parsed_rule.P, ...segmentDetail[partajNo].parsed_rule.M]?.find(pr => pr.police === p.code)
        if (checkKazanim(p.hedefPrim, p.hedefAdet, p.adet, p.prim, rule?.operator)) {
          total += getKazanilanPrim(rule?.prim, p.prim)
        }
      })
      setTotalPrim(prevState => ({
        ...prevState,
        [partajNo]: total
      }))
    })
  }, [policePrim, segmentDetail])

  function getKazanilanPrim(primYuzde, tutar) {
    return (tutar * primYuzde) / 100
  }

  return (
    <React.Fragment>
      <div ref={printableContentRef} className="page-content">
        <Container fluid className="min-vh-100">
          <ToastContainer transition={Slide} />
          <Breadcrumbs title={props.t("Dashboards")}
                       breadcrumbItem={props.t("Dashboard")} />
          <Form>
            <Row className="mb-4">
              <Col sm={6} className="col-xl">
                <FormGroup className="mb-3">
                  <Label className="form-label">Segment</Label>
                  <Select
                    isClearable={true}
                    name="selectedSegment"
                    onChange={(value) => handleSelect(value, "selectedSegment")}
                    value={formData.selectedSegment}
                    options={segments}
                    placeholder="Tümü"
                    styles={selectStyles}
                    getOptionValue={item => item.id}
                    getOptionLabel={item => item.segment_name}
                  />
                </FormGroup>
              </Col>
              <Col sm={6} className="col-xl">
                <FormGroup className="mb-3">
                  <Label className="form-label">Bölge</Label>
                  <Select
                    isClearable={true}
                    name="selectedRegion"
                    onChange={(value) => handleSelect(value, "selectedRegion")}
                    value={formData.selectedRegion}
                    options={bolgeler}
                    placeholder="Tümü"
                    styles={selectStyles}
                    getOptionValue={item => item.bolge_kod}
                    getOptionLabel={item => item.bolge_adi}
                  />
                </FormGroup>
              </Col>
              {/*<Col sm={6} className="col-xl">
                <FormGroup className="mb-3">
                  <Label className="form-label">Bölge Müdürü</Label>
                  <Select
                    isClearable={true}
                    name="selectedManager"
                    onChange={(value) => handleSelect(value, "selectedManager")}
                    value={formData.selectedManager}
                    options={bmKullanicilar}
                    placeholder="Tümü"
                    styles={selectStyles}
                    getOptionValue={item => item.useruniqid}
                    getOptionLabel={item => item.ad + " " + item.soyad}
                  />
                </FormGroup>
              </Col>
              <Col sm={6} className="col-xl">
                <FormGroup className="mb-3">
                  <Label className="form-label">ASU</Label>
                  <Select
                    isClearable={true}
                    name="selectedASU"
                    onChange={(value) => handleSelect(value, "selectedASU")}
                    value={formData.selectedASU}
                    options={asuKullanicilar}
                    placeholder="Tümü"
                    styles={selectStyles}
                    getOptionValue={item => item.useruniqid}
                    getOptionLabel={item => item.ad + " " + item.soyad}
                  />
                </FormGroup>
              </Col>
              */}
              <Col sm={6} className="col-xl">
                <FormGroup className="mb-3">
                  <Label className="form-label">SKS</Label>
                  <Select
                    isClearable={true}
                    isMulti={true}
                    name="selectedAgency"
                    placeholder="Seçiniz"
                    onChange={(value) => handleSelect(value, "selectedAgency")}
                    value={formData.selectedAgency}
                    options={acenteler}
                    styles={selectStyles}
                    getOptionValue={item => item.id}
                    getOptionLabel={item => `${item.acente_unvan}`}
                  />
                </FormGroup>
              </Col>
              {/*<Col sm={2}>
                  <FormGroup>
                    <Label for="search">Ara</Label>
                    <Input
                      id="search"
                      name="searchInput"
                      value={formData.searchInput}
                      onChange={handleSearchInputChange}
                    />
                  </FormGroup>
                </Col>*/}
              <Col sm={6} className="col-xl align-self-end">
                <div className="mb-3">
                  <Button type="button" color="primary" className="w-md" onClick={handleSearch}>
                    Filtrele
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>

          <Row className="d-flex justify-content-start my-2">
            <Col sm={2}>
              <button className="btn btn-primary" onClick={handleSaveAsReport}>Rapor Oluştur</button>
            </Col>
          </Row>
          <Row className="my-4"></Row>
          {acenteLoading ? (
              <Spinners setLoading={setLoading} />
            ) :
            <Row>
              <Col sm={12} md={12}>
                {userAgency.map((acente, index) => {
                    const status = collapse.includes(acente.id)
                    return (
                      <Card key={index}>
                        <CardBody>
                          <div className="d-flex align-items-center justify-content-between">
                            <h4>{acente.acente_unvan}</h4>
                            <Button color="primary" onClick={() => showAgency(acente, acente.id)}
                                    style={{ marginBottom: "1rem" }}>{status ? "Gizle" : "Göster"}
                              <i
                                className={`fa ${status ? "fa-arrow-alt-circle-up" : "fa-arrow-alt-circle-down"} ps-2`}></i>
                            </Button>
                          </div>
                        </CardBody>
                        <Collapse
                          isOpen={status}
                        >
                          <CardBody className="mt-0">
                            {loading && loading[acente.acente_partajno] ? (
                                <Spinners setLoading={setLoading} />
                              ) :
                              <>
                                {error && error[acente.acente_partajno] ?
                                  <Card className="px-4">
                                    <h5 className="text-danger">Veri bulunamadı</h5>
                                  </Card> :
                                  <Card className="px-4">
                                    <h5>Özet</h5>
                                    <div className="d-flex pb-2">
                                      <h6 className="text-success pe-2">Kazanılan Toplam Prim Tutarı:</h6>
                                      {<h6 className="">{totalPrim ? totalPrim[acente.acente_partajno] : 0} TL</h6>}
                                    </div>
                                    <h5>{segmentDetail && segmentDetail[acente.acente_partajno] && segmentDetail[acente.acente_partajno].segment_name + " - "} Gezegen
                                      Ana Kuralı </h5>
                                    {segmentDetail && segmentDetail[acente.acente_partajno] && segmentDetail[acente.acente_partajno].segment_description?.split(".").map((rule, index) => (
                                      <p key={index} className="text-dark mb-2">{rule}</p>
                                    ))}
                                  </Card>
                                }

                                {(policePrim && policePrim[acente.acente_partajno] && policePrim[acente.acente_partajno].length > 0) && policePrim[acente.acente_partajno].map((p, index) => {
                                  const rule = [...segmentDetail[acente.acente_partajno].parsed_rule.P, ...segmentDetail[acente.acente_partajno].parsed_rule.M]?.find(pr => pr.police == p.code)
                                  const prim = (rule?.prim && p.prim) ? getKazanilanPrim(rule?.prim, p.prim) : 0
                                  return (<Row style={{
                                    boxShadow: "rgb(245 241 241 / 34%) 0px 0px 11px 8px",
                                    border: "2px solid #f3eded3b",
                                    borderRadius: "10px"
                                  }} className="pt-2 m-2"
                                               key={index}>
                                    <Col sm={12} md={6}>
                                      <Card className="pt-2">
                                        <h5 className="text-center">{p.name}</h5>
                                        <BarChart series={[
                                          {
                                            data: [p.primOran]
                                          }
                                        ]} name={p.name} title={"Tutar Bazlı Prim Hedef İlişkisi"} />

                                      </Card>
                                    </Col>
                                    <Col sm={12} md={6}>
                                      <Card className="pt-2">
                                        <h5 className="text-center">{p.name}</h5>
                                        <BarChart series={[
                                          {
                                            data: [p.adetOran]
                                          }
                                        ]} name={p.name}
                                                  title={"Adet Bazlı Prim Hedef İlişkisi"} />
                                      </Card>

                                    </Col>
                                    <Row className="d-flex justify-content-center">
                                      <Col sm={12}>
                                        <table className="mb-2" align="center">
                                          <tbody>
                                          <tr>
                                            <td> Hedeflenen Satış</td>
                                            <td>:</td>
                                            <td>
                                              <strong>{` ${p.hedefAdet || 0} Adet ${rule?.operator === "&&" ? "ve " : "veya " || ""} ${p.hedefPrim || 0}  TL `}</strong>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td> Gerçekleşen Satış</td>
                                            <td> :</td>
                                            <td>
                                              <div className="d-flex align-items-center mb-0">
                                                <h6
                                                  className={`${getTextColor(p?.hedefAdet || 0, p?.adet || 0)} mb-0`}> {p.adet || 0} Adet</h6>
                                                <h6
                                                  className="mb-0 px-2"> {rule?.operator === "&&" ? " ve " : " veya " || ""}</h6>
                                                <h6
                                                  className={`${getTextColor(p?.hedefPrim || 0, p?.prim || 0)} mb-0`}>{p.prim || 0} TL </h6>

                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Gerçekleşme Oranları</td>
                                            <td> :</td>
                                            <td>
                                              <div className="d-flex align-items-center mb-0">
                                                <h6
                                                  className={`${getTextColor(100, p?.adetOran || 0)} mb-0 pe-2`}>
                                                  Satış Adet Oranı %{p.adetOran || 0} </h6>
                                                <h6
                                                  className={`${getTextColor(100, p?.primOran || 0)} mb-0`}> Satış Tutar
                                                  Oranı
                                                  %{p.primOran || 0}
                                                </h6>
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Kazanılacak Prim Oranı</td>
                                            <td> :</td>
                                            <td>
                                              <strong>% {rule?.prim || 0}</strong>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Prim Kazanım Durumu</td>
                                            <td> :</td>
                                            <td>
                                              {checkKazanim(p.hedefPrim, p.hedefAdet, p.adet, p.prim, rule?.operator) ? (
                                                parseInt(rule?.prim) === 0 ? (
                                                  <h6 className="text-danger mb-0">Bu poliçe tipine prim
                                                    verilmemektedir </h6>
                                                ) : (
                                                  (() => {
                                                    return <h6
                                                      className="text-success mb-0">{`${prim} TL Prim Kazanıldı`}</h6>
                                                  })()
                                                )
                                              ) : (
                                                <h6 className="text-danger mb-0">Prim kazanılamadı</h6>
                                              )}
                                            </td>
                                          </tr>
                                          </tbody>
                                        </table>
                                      </Col>
                                    </Row>
                                  </Row>)
                                })
                                }
                              </>
                            }
                          </CardBody>
                        </Collapse>
                      </Card>
                    )
                  }
                )}
              </Col>
            </Row>
          }
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Dashboard)
