import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"

import * as Yup from "yup"
import { useFormik } from "formik"
import DeleteModal from "../../components/Common/DeleteModal"
import { Slide, toast, ToastContainer } from "react-toastify"
import Spinners from "../../components/Common/Spinner"
import TableContainer from "../../components/Common/TableContainer"

import {
  addPoliceRequest,
  deletePoliceRequest,
  listPolicelerRequest,
  updatePoliceRequest
} from "../../store/policeTipleri/actions"

const PoliceTipleri = (props) => {
  //meta title
  document.title = "Poliçe Tipleri | ZUZZUU"
  const dispatch = useDispatch()
  const [police, setPolice] = useState()
  const [isEdit, setIsEdit] = useState(false)


  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id:  (police && police.id) || "",
      police_name: (police && police.police_name) || "",
      police_description: (police && police.police_description) || "",
      police_partition_type_code: (police && police.police_partition_type_code) || "",
      police_partition_type_code_aciklama: (police && police.police_partition_type_code_aciklama) || "",
      police_urun_paket_aciklama: (police && police.police_urun_paket_aciklama) || "",
      police_urun_kodu: (police && police.police_urun_kodu) || "",
      police_urun_kodu_aciklama: (police && police.police_urun_kodu_aciklama) || "",
      police_brans: (police && police.police_brans) || "",
      police_brans_aciklama: (police && police.police_brans_aciklama) || "",
      police_alt_brans_id: (police && police.police_alt_brans_id) || "",
      police_alt_brans_aciklama: (police && police.police_alt_brans_aciklama) || ""
    },
    validationSchema: Yup.object({
      police_name: Yup.string().required("Lütfen Poliçe Adını yazınız"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updatePolice = {
          id: police.id,
          ...values
        }

        // update bolge
        dispatch(updatePoliceRequest(updatePolice))
      } else {
        const { id, ...newPolice } = values
        dispatch(addPoliceRequest(newPolice))
      }
      toggle()
    }
  })

  const { policeler, loading, error } = useSelector(state => state.Policeler)

  const [isLoading, setLoading] = useState(loading)

  const [modal, setModal] = useState(false)

  useEffect(() => {
    dispatch(listPolicelerRequest())
    setIsEdit(false)
  }, [dispatch])

  useEffect(() => {
    if (error) {
      toast.error(error)
    }
  }, [error])

  const toggle = () => {
    setModal(!modal)
  }

  const handleKullaniciClick = (arg) => {
    const police = arg
    setPolice({
      id: police.id,
      police_name: police.police_name,
      police_description: police.police_description,
      police_partition_type_code: police.police_partition_type_code,
      police_partition_type_code_aciklama: police.police_partition_type_code_aciklama,
      police_urun_paket_aciklama: police.police_urun_paket_aciklama,
      police_urun_kodu: police.police_urun_kodu,
      police_brans: police.police_brans,
      police_brans_aciklama: police.police_brans_aciklama,
      police_alt_brans_id: police.police_alt_brans_id,
      police_alt_brans_aciklama: police.police_alt_brans_aciklama
    })

    setIsEdit(true)

    toggle()
  }
  const handleAddPoliceTipiClick = () => {
    setPolice({})
    setIsEdit(false)
    toggle()
  }
  //delete bolge
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = (policeData) => {
    setPolice(policeData)
    setDeleteModal(true)
  }

  const handleDeleteRecord = () => {
    if (police && police.id) {
      dispatch(deletePoliceRequest(police.id))
    }
    setDeleteModal(false)
  }


  const columns = useMemo(
    () => [
      {
        header: "#",
        id: "#",
        cell: (cellProps) => (
          <p className="text-dark mb-2">
            {cellProps.row.index + 1}
          </p>

        ),
        enableColumnFilter: false,
        enableSorting: true
      },
      {
        header: "Poliçe Adı",
        accessorKey: "police_name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => (
          <>
            <h5 className="text-truncate font-size-14">
              <p className="text-dark mb-0">
                {cellProps.row.original.police_name}
              </p>
            </h5>
          </>
        )
      },
      {
        header: "Açıklama",
        accessorKey: "police_description",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => (
          <>
            <h5 className="text-truncate font-size-14">
              <p className="text-muted mb-0">
                {cellProps.row.original.police_description}
              </p>
            </h5>
          </>
        )
      },
      {
        header: "Bölüm Türü Açıklama",
        accessorKey: "police_partition_type_code_aciklama",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => (
          <>
            <h5 className="text-truncate font-size-14">
              <p className="text-muted mb-0">
                {cellProps.row.original.police_partition_type_code_aciklama}
              </p>
            </h5>
          </>
        )
      },
      {
        header: "Ürün Paket Açıklama",
        accessorKey: "police_urun_paket_aciklama",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => (
          <>
            <h5 className="text-truncate font-size-14">
              <p className="text-muted mb-0">
                {cellProps.row.original.police_urun_paket_aciklama}
              </p>
            </h5>
          </>
        )
      },
      {
        header: "Ürün Kodu Açıklama",
        accessorKey: "police_urun_kodu_aciklama",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => (
          <>
            <h5 className="text-truncate font-size-14">
              <p className="text-muted mb-0">
                {cellProps.row.original.police_urun_kodu_aciklama}
              </p>
            </h5>
          </>
        )
      },
      {
        header: "Branş Açıklama",
        accessorKey: "police_brans_aciklama",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => (
          <>
            <h5 className="text-truncate font-size-14">
              <p className="text-muted mb-0">
                {cellProps.row.original.police_brans_aciklama}
              </p>
            </h5>
          </>
        )
      },
      {
        header: "Alt Branş Açıklama",
        accessorKey: "police_alt_brans_aciklama",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => (
          <>
            <h5 className="text-truncate font-size-14">
              <p className="text-muted mb-0">
                {cellProps.row.original.police_alt_brans_aciklama}
              </p>
            </h5>
          </>
        )
      },
      {
        header: "İşlemler",
        accessorKey: "actions",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps) => (
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => {
                const rowData = cellProps.row.original
                handleKullaniciClick(rowData)
              }}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => {
                const rowData = cellProps.row.original
                onClickDelete(rowData)
              }}>
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
            </Link>
          </div>
        )
      }
    ],
    []
  );


  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteRecord}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <ToastContainer transition={Slide} />
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("")}
            breadcrumbItem={props.t("Poliçe Tipleri")}
          />
          {loading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {policeler &&
                      <TableContainer
                        columns={columns}
                        data={policeler}
                        isGlobalFilter={true}
                        isAddButton={true}
                        isPagination={true}
                        isCustomPageSize={true}
                        handleUserClick={handleAddPoliceTipiClick}
                        SearchPlaceholder="Ara"
                        buttonClass={null}
                        buttonName="Yeni Ekle"
                        tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                        theadClass="table-light"
                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                        pagination="pagination"
                      />}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {isEdit ? "Poliçe Düzenle" : "Poliçe Ekle"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col xs={6}>
                    <div className="mb-3">
                      <Label>Poliçe Adı</Label>
                      <Input
                        name="police_name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.police_name || ""}
                        invalid={validation.touched.police_name && validation.errors.police_name ? true : false}
                      />
                      {validation.touched.police_name && validation.errors.police_name ? (
                        <FormFeedback type="invalid">{validation.errors.police_name}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="mb-3">
                      <Label>Poliçe Açıklama</Label>
                      <Input
                        name="police_description"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.police_description || ""}
                        invalid={validation.touched.police_description && validation.errors.police_description ? true : false}
                      />
                      {validation.touched.police_description && validation.errors.police_description ? (
                        <FormFeedback type="invalid">{validation.errors.police_description}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <div className="mb-3">
                      <Label>Bölüm Tipi Kodu</Label>
                      <Input
                        name="police_partition_type_code"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.police_partition_type_code || ""}
                        invalid={validation.touched.police_partition_type_code && validation.errors.police_partition_type_code ? true : false}
                      />
                      {validation.touched.police_partition_type_code && validation.errors.police_partition_type_code ? (
                        <FormFeedback type="invalid">{validation.errors.police_partition_type_code}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="mb-3">
                      <Label>Bölüm Tipi Açıklama</Label>
                      <Input
                        name="police_partition_type_code_aciklama"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.police_partition_type_code_aciklama || ""}
                        invalid={validation.touched.police_partition_type_code_aciklama && validation.errors.police_partition_type_code_aciklama ? true : false}
                      />
                      {validation.touched.police_partition_type_code_aciklama && validation.errors.police_partition_type_code_aciklama ? (
                        <FormFeedback type="invalid">{validation.errors.police_partition_type_code_aciklama}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>

                  <Col xs={6}>
                    <div className="mb-3">
                      <Label>Ürün Kodu</Label>
                      <Input
                        name="police_urun_kodu"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.police_urun_kodu || ""}
                        invalid={validation.touched.police_urun_kodu && validation.errors.police_urun_kodu ? true : false}
                      />
                      {validation.touched.police_urun_kodu && validation.errors.police_urun_kodu ? (
                        <FormFeedback type="invalid">{validation.errors.police_urun_kodu}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="mb-3">
                      <Label>Ürün Kodu Açıklama</Label>
                      <Input
                        name="police_urun_kodu_aciklama"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.police_urun_kodu_aciklama || ""}
                        invalid={validation.touched.police_urun_kodu_aciklama && validation.errors.police_urun_kodu_aciklama ? true : false}
                      />
                      {validation.touched.police_urun_kodu_aciklama && validation.errors.police_urun_kodu_aciklama ? (
                        <FormFeedback type="invalid">{validation.errors.police_urun_kodu_aciklama}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>

                  <Col xs={6}>
                    <div className="mb-3">
                      <Label>Branş</Label>
                      <Input
                        name="police_brans"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.police_brans || ""}
                        invalid={validation.touched.police_brans && validation.errors.police_brans ? true : false}
                      />
                      {validation.touched.police_brans && validation.errors.police_brans ? (
                        <FormFeedback type="invalid">{validation.errors.police_brans}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="mb-3">
                      <Label>Branş Açıklama</Label>
                      <Input
                        name="police_brans_aciklama"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.police_brans_aciklama || ""}
                        invalid={validation.touched.police_brans_aciklama && validation.errors.police_brans_aciklama ? true : false}
                      />
                      {validation.touched.police_brans_aciklama && validation.errors.police_brans_aciklama ? (
                        <FormFeedback type="invalid">{validation.errors.police_brans_aciklama}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>

                  <Col xs={6}>
                    <div className="mb-3">
                      <Label>Ürün Paket Açıklama</Label>
                      <Input
                        name="police_urun_paket_aciklama"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.police_urun_paket_aciklama || ""}
                        invalid={validation.touched.police_urun_paket_aciklama && validation.errors.police_urun_paket_aciklama ? true : false}
                      />
                      {validation.touched.police_urun_paket_aciklama && validation.errors.police_urun_paket_aciklama ? (
                        <FormFeedback type="invalid">{validation.errors.police_urun_paket_aciklama}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="mb-3">
                      <Label>Alt Branş Id</Label>
                      <Input
                        name="police_alt_brans_id"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.police_alt_brans_id || ""}
                        invalid={validation.touched.police_alt_brans_id && validation.errors.police_alt_brans_id ? true : false}
                      />
                      {validation.touched.police_alt_brans_id && validation.errors.police_alt_brans_id ? (
                        <FormFeedback type="invalid">{validation.errors.police_alt_brans_id}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <div className="mb-3">
                      <Label>Alt Branş Açıklama</Label>
                      <Input
                        name="police_alt_brans_aciklama"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.police_alt_brans_aciklama || ""}
                        invalid={validation.touched.police_alt_brans_aciklama && validation.errors.police_alt_brans_aciklama ? true : false}
                      />
                      {validation.touched.police_alt_brans_aciklama && validation.errors.police_alt_brans_aciklama ? (
                        <FormFeedback type="invalid">{validation.errors.police_alt_brans_aciklama}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        color="primary"
                        type="submit"
                        className="save-user"
                      >
                        Kaydet
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

PoliceTipleri.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(PoliceTipleri)
