import * as types from './actionTypes';

const initialState = {
  acenteler: [],
  loading: false,
  error: null,
};

const acenteler = (state = initialState, action) => {
  switch (action.type) {
    case types.LIST_ACENTELER_REQUEST:
    case types.ADD_ACENTE_REQUEST:
    case types.UPDATE_ACENTE_REQUEST:
    case types.DELETE_ACENTE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.LIST_ACENTELER_SUCCESS:
      return {
        ...state,
        acenteler: action.payload,
        loading: false,
      };
    case types.ADD_ACENTE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.UPDATE_ACENTE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.DELETE_ACENTE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.LIST_ACENTELER_FAILURE:
    case types.ADD_ACENTE_FAILURE:
    case types.UPDATE_ACENTE_FAILURE:
    case types.DELETE_ACENTE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export default acenteler;
