import { jwtDecode } from "jwt-decode"

export function LogoutOnNonAdmin() {

  if (sessionStorage.getItem("authUser")) {
    const obj = JSON.parse(sessionStorage.getItem("authUser"))
    const user_role = jwtDecode(obj.token).role;
    if (user_role === "admin") {
      return true;
    }
    sessionStorage.removeItem("authUser")
  }
  return false;
}

