export const LIST_ACENTELER_REQUEST = 'LIST_ACENTELER_REQUEST';
export const LIST_ACENTELER_SUCCESS = 'LIST_ACENTELER_SUCCESS';
export const LIST_ACENTELER_FAILURE = 'LIST_ACENTELER_FAILURE';

export const ADD_ACENTE_REQUEST = 'ADD_ACENTE_REQUEST';
export const ADD_ACENTE_SUCCESS = 'ADD_ACENTE_SUCCESS';
export const ADD_ACENTE_FAILURE = 'ADD_ACENTE_FAILURE';

export const UPDATE_ACENTE_REQUEST = 'UPDATE_ACENTE_REQUEST';
export const UPDATE_ACENTE_SUCCESS = 'UPDATE_ACENTE_SUCCESS';
export const UPDATE_ACENTE_FAILURE = 'UPDATE_ACENTE_FAILURE';

export const DELETE_ACENTE_REQUEST = 'DELETE_ACENTE_REQUEST';
export const DELETE_ACENTE_SUCCESS = 'DELETE_ACENTE_SUCCESS';
export const DELETE_ACENTE_FAILURE = 'DELETE_ACENTE_FAILURE';
export const GET_ACENTE_DETAIL_REQUEST = 'GET_ACENTE_DETAIL_REQUEST';
export const GET_ACENTE_DETAIL_SUCCESS = 'GET_ACENTE_DETAIL_SUCCESS';
export const GET_ACENTE_DETAIL_FAILURE = 'GET_ACENTE_DETAIL_FAILURE';