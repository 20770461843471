
import { takeLatest, call, put } from "redux-saga/effects"
import * as types from "./actionTypes"
import * as api from "helpers/api_helper.js"
import { reverseSortById } from "../../helpers/utils"

function* listAcentelerSaga() {
  try {
    const acenteler = yield call(api.listAcenteler)
    yield put({ type: types.LIST_ACENTELER_SUCCESS, payload: reverseSortById(acenteler) })
  } catch (error) {
    yield put({ type: types.LIST_ACENTELER_FAILURE, payload: error.message })
  }
}

function* addAcenteSaga(action) {
  try {
    yield call(api.addAcente, action.payload)
    yield call(listAcentelerSaga) // Refresh segments after addition
  } catch (error) {
    yield put({ type: types.ADD_ACENTE_FAILURE, payload: error.message })
  }
}

function* updateAcenteSaga(action) {
  try {
    const { acenteData } = action.payload
    yield call(api.updateAcente, acenteData.id, acenteData)
    yield call(listAcentelerSaga) // Refresh segments after update
  } catch (error) {
    yield put({ type: types.UPDATE_ACENTE_FAILURE, payload: error.message })
  }
}

function* deleteAcenteSaga(action) {
  try {
    const id = action.payload
    yield call(api.deleteAcente, id)
    yield call(listAcentelerSaga) // Refresh segments after deletion
  } catch (error) {
    yield put({ type: types.DELETE_ACENTE_FAILURE, payload: error.message })
  }
}

export default function* segmentSaga() {
  yield takeLatest(types.LIST_ACENTELER_REQUEST, listAcentelerSaga)
  yield takeLatest(types.ADD_ACENTE_REQUEST, addAcenteSaga)
  yield takeLatest(types.UPDATE_ACENTE_REQUEST, updateAcenteSaga)
  yield takeLatest(types.DELETE_ACENTE_REQUEST, deleteAcenteSaga)
}
